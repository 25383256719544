<template>
  <div>
    <h1>Inserir novo</h1>
    <div class="container">
      <FormStates
        :enviar="enviar"
        :estado="estado"
        :show="show"
        :saving="saving"
        :isReadOnly="false"
      />
    </div>
  </div>
</template>

<script>


import FormStates from "@/modules/states/components/FormStates";
import {mapActions} from "vuex";
export default {
  components: {FormStates},
  data() {
    return {
      debug:false,
      show: true,
      saving:false,
      estado:{
        initials:"",
        name:"",
        ibge_id:"",
        onlyRead:false
      }
    }
  },
  methods: {
    ...mapActions("states", ["ActionStore"]),
    async enviar(event) {
      event.preventDefault();
      this.saving = true;
      try {
        await this.ActionStore(this.estado).then(() => { this.saving=false });
      }catch (e){
        if(e.status==400){
          alert("Problemas ao inserir o estado.\nMensagem: "+e.body.message);
        }
      }


    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
    },
  },


  async created() {

  },
};
</script>

<style>
.row{
  margin:10px auto 10px auto;
}
</style>
